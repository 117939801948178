///*----------------------------------*\
//  #COLORS
//\*----------------------------------*/

// Set up a colour palette which allows us to theme the entire project from one
// location.

// Colour palette.
$color-brand-primary:               #E69883 !default;
$color-brand-primary-75:            #1F3D8B !default;
$color-brand-primary-40:            #D90814 !default;
$color-brand-primary-10:            #1F3D8B !default;
$color-brand-primary-02:            #1F3D8B !default;

$color-brand-primary-highlight:     lighten($color-brand-primary, 5%) !default;
$color-brand-primary-shadow:        darken($color-brand-primary,  5%) !default;

$color-brand-secondary:             #D90814 !default;
$color-brand-secondary-highlight:   lighten($color-brand-secondary, 5%) !default;
$color-brand-secondary-shadow:      darken($color-brand-secondary,  5%) !default;

$color-ui:                          #e4e4e4 !default;

$color-grey:                        #bbbbbb !default;
$color-grey-dark:                   #535353 !default;
$color-grey-xdark:                  #323232 !default;
$color-grey-light:                  #dddddd !default;
$color-grey-xlight:                 #f5f5f5 !default;

$color-white:                       #fff !default;

$color-black:                       #000 !default;

$color-positive:                    #94c048;
$color-neutral:                     #0099fa;
$color-warning:                     #fd9434;
$color-error:                       #ff4444;


// Reassign literal variables to meaningful ones.
$color-page-text:                       $color-black;
$color-page-background:                 #ffffff;

$color-logo:                            $color-black;
$color-logo-primary:                    $color-brand-primary;
$color-logo-secondary:                  $color-brand-primary;

$color-links:                           $color-brand-primary; //inherit; ? it was 'inherit' but doesnt state why...
$color-links-hover:                     $color-brand-primary-shadow;
$color-links-prose:                     $color-brand-primary;
$color-links-prose-hover:               $color-brand-primary-shadow;

$color-page-head:                       #fff;
$color-page-head-background:            $color-brand-secondary;

$color-masthead-background:             $color-brand-secondary;

$color-btn-text:                        $color-white;
$color-btn-text-hover:                  $color-grey-xlight;
$color-btn-background:                  $color-black;
$color-btn-background-hover:            $color-brand-secondary-shadow;
// $color-btn-secondary:                   #fff;
// $color-btn-secondary-background:        $color-brand-secondary;
// $color-btn-secondary-background-hover:  $color-brand-secondary-shadow;

// $color-calendar:                        #fff;
// $color-calendar-title:                  #fff;
// $color-calendar-title-background:       $color-brand-primary;
// $color-calendar-busy:                   #fff;
// $color-calendar-busy-background:        $color-brand-secondary;
// $color-calendar-outside:                $color-grey-light;

$color-page-foot:                       #fff;
$color-page-foot-background:            $color-grey-dark;;

$color-panel-background:                #fff;
$color-panel-inverse:                   #fff;
$color-panel-inverse-background:        $color-brand-primary;
$color-panel-link:                      $color-grey-dark;
$color-panel-link-hover:                $color-brand-primary;

$color-testimonial-background:          #fff;
$color-testimonial-border:              $color-brand-primary;
$color-testimonial-source:              $color-grey;

$color-headline:                        $color-grey-light;

$color-sub-content-bg:                  rgba(0, 0, 0, 0.05);
$color-sub-content:                     $color-grey;

$color-pagination:                      $color-white;
$color-pagination-border:               $color-pagination;
$color-pagination-bg:                   $color-brand-primary;
$color-pagination-bg-hover:             $color-brand-primary-shadow;
$color-pagination-bg-current:           $color-brand-primary-highlight;
